<template>
  <div>
    <b-sidebar
      id="sidebar-request-deletion-approval"
      sidebar-class="sidebar-lg"
      :visible="requestDeletionAprovalSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateRequestDeletionAprovalSidebar({ visible: val })"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h4>
            Reprovar a solicitação de exclusão do contrato
            {{ requestDeletionAprovalSidebar.id }}
          </h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          Por favor informe o motivo que esta reprovando a solicitação!
          <br />
          <br />
          <b-col cols="12">
            <b-form-group label="" label-for="description">
              <b-form-textarea
                id="description"
                placeholder=""
                v-model="description"
                :required="true"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              @click="validateForm()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              
              type="submit"
            >
              {{ saving ? "Salvando a reprovação..." : "Reprovar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormInvalidFeedback,
  BFormInput,
  BFormTextarea,
  BCol
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import MoneyInput from "@/modules/shared/components/MoneyInput";
import { moneyToFloat } from "@/helpers/converters";
import * as types from "../store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    MoneyInput,
    BFormTextarea,
    BCol
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      description: "",
      contract_id: "",
    };
  },
  computed: {
    ...mapGetters({
      requestDeletionAprovalSidebar: types.REQUEST_DELETION_APROVAL_SIDEBAR,
    }),
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  mounted() {},
  methods: {
    ...mapMutations({
      mutateRequestDeletionAprovalSidebar:
        types.MUTATE_REQUEST_DELETION_APROVAL_SIDEBAR,
    }),
    ...mapActions({
      saveRequestDeletionAprovalSidebar:
        types.SAVE_REQUEST_DELETION_APROVAL_SIDEBAR,
    }),
    validateForm() {
      if(this.description == ''){
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "O campo motivo não pode ser vazio",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return false;          
      }
    },
    onSubmit() {
      this.saving = true
      const request = new FormData();

      request.append("contract_id", this.requestDeletionAprovalSidebar.id);
      request.append("approval_decision", this.description);

      this.saveRequestDeletionAprovalSidebar(request)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A solicitação foi adicionado com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.file = undefined;
          this.gross_value = undefined;
          this.mutateRequestDeletionAprovalSidebar({ visible: false });
          this.requestDeletionAprovalSidebar.saveAction();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao adicionar o recebimento. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
