<template>
  <div>
    <request-deletion-aproval-sidebar />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-space justify-content-end mb-1 mb-md-0"
          >
            <b-button
              v-if="selectedRequests.length > 0"
              variant="outline-danger"
              size="sm"
              @click="denyChecked"
              style="margin-right: 10px;"
            >
              <span class="text-nowrap">Reprovar solicitação</span>
            </b-button>
            
            <b-button
              v-if="selectedRequests.length > 0"
              variant="outline-danger"
              size="sm"
              @click="acceptChecked"
            >
              <span class="text-nowrap">Excluir selecionados</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas solicitações para esta busca"
        :busy="loading"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: select -->
        <template #head(select)="data">
          <b-form-checkbox v-model="checkedAll" @change="checkAll" />
        </template>
        <template #cell(select)="data">
          <b-form-checkbox
            v-model="selectedRequests"
            :value="data.item.contract_id"
            @click.native.stop
          />
        </template>
        <!-- Column: User -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.customer_name">
              <b-button
                :id="`customer-pop-icon-${data.item.contract_id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <user-detail-popover
                :target="`customer-pop-icon-${data.item.contract_id}`"
                type="customer"
                :id="data.item.id_customer"
                :showDetailUrl="true"
                :showWhastapp="true"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.customer_name }}
              </span>
            </template>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.created_at | dateTime
            }}</span>
          </div>
        </template>
        <!-- Column: solução financeira -->
        <template #cell(financial_solution_name)="data">
          <div class="text-wrap">
            <template v-if="data.item.financial_solution_name">
              <b-button
                :id="`financial-solution-pop-icon-${data.item.contract_id}`"
                class="btn-icon"
                variant="flat"
                style="padding: 0"
              >
                <feather-icon
                  size="16"
                  icon="ChevronsUpIcon"
                  class="cursor-pointer"
                />
              </b-button>
              <financial-solution-popover
                :target="`financial-solution-pop-icon-${data.item.contract_id}`"
                :id="data.item.financial_solution_id"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.financial_solution_name }}
              </span>
            </template>
          </div>
        </template>
        <!-- Column: Descrição -->
        <template #cell(description)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.description == "undefined"?  
                "Não informada": data.item.description

            }}</span>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(productName)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.product.name
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret right>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="toAccept(data.item)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Aprovar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="requestDeletion(data.item.contract_id)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Reprovar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { avatarText } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  CONTRACT_RESOURCE,
} from "@/constants/resources";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../../contract/store/types";
import RequestDeletionAprovalSidebar from "../components/RequestDeletionAprovalSidebar";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import FinancialSolutionPopover from "../components/FinancialSolutionPopover";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormCheckbox,
    vSelect,
    RequestDeletionAprovalSidebar,
    UserDetailPopover,
    FinancialSolutionPopover,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "select", label: "" },
        { key: "contract_id", label: "Número do contrato", sortable: true },
        { key: "user_name", label: "Solicitante" },
        { key: "created_at", label: "Data de solicitação" },
        { key: "financial_solution_name", label: "Solução Financeira" },
        { key: "customer_name", label: "Cliente" },
        { key: "status_old", label: "Status do contrato" },
        { key: "description", label: "Descrição" },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      selectedRequests: [],
      checkedAll: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      deletionRequestSearchResult: types.DELETION_REQUEST_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.deletionRequestSearchResult?.data || [];
    },
    totalItems: function () {
      return this.deletionRequestSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.deletionRequestSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.deletionRequestSearchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchDeletionRequest: types.SEARCH_DELETION_REQUEST,
      deleteFinancialSolution: types.DELETE_DELETION_REQUEST,
      openRequestDeletionAprovalSidebar:
        types.OPEN_REQUEST_DELETION_APROVAL_SIDEBAR,
      deleteSelectedDeletionRequest: types.DELETE_SELECTED_DELETION_REQUEST,
      refuseSelectedDeletionRequest: types.REFUSE_SELECTED_DELETION_REQUEST
    }),
    search(currentPage) {
      this.loading = true;
      this.searchDeletionRequest({
        keyword: this.searchQuery,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as solicitações. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
          this.checkedAll = false;
          this.selectedRequests = [];
        });
    },
    goToContractDetails(row, index) {
      var routeData = this.$router.resolve({
        name: "contract-details-summary",
        params: { id: row.contract_id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    refuseRequest(item) {
      alert("Reprovando");
      return false;
    },
    requestDeletion(contractId) {
      this.openRequestDeletionAprovalSidebar({
        id: contractId,
        saveAction: this.search,
      });
    },
    toAccept(item) {
      const request = new FormData();
      request.append("contract_id", item.contract_id);
      this.$swal({
        title: "Tem certeza?",
        text: `Aprovando está ação o contrato ${item.contract_id} será removido do sistema`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.deleteFinancialSolution(request)
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `O contrato ${item.contract_id} foi removido`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover o contrato ${item.contract_id}. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
              this.checkedAll = false;
            });
        }
      });
    },
    checkAll(value) {
      if (value) {
        this.selectedRequests = _.map(this.items, (e) => {
          return e.contract_id;
        });
      } else {
        this.selectedRequests = [];
      }
    },
    acceptChecked() {
      this.$swal({
        title: "Tem certeza?",
        text: `Aprovando está ação os contratos marcados serão removidos do sistema`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.deleteSelectedDeletionRequest({
            selectedRequests: this.selectedRequests,
          })
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `Os contratos foram removidos`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover os contratos. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
              this.checkedAll = false;
            });
        }
      });
    },
    denyChecked() {
      this.$swal({
        title: "Tem certeza?",
        text: "Aprovando está ação os contratos marcados serão revertidos ao status anterior",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.$swal({
            title: 'Informe o motivo',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off',
              style: 'color: #FFFFFF' 
            },
            showCancelButton: true,
            confirmButtonText: 'Reprovar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            background: "rgb(40, 48, 70)", 
            customClass: {
              confirmButton: "btn btn-primary", 
              cancelButton: "btn btn-secondary ml-1", 
              popup: "swal-popup", 
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((inputResult) => {
            if (inputResult.isConfirmed) {
              this.loading = true;
              this.refuseSelectedDeletionRequest({
                selectedRequests: this.selectedRequests,
                approval_decision: inputResult.value
              })
                .then((response) => {
                  this.search();
                  this.toast({
                    component: ToastificationContent,
                    props: {
                      title: "Sucesso",
                      text: `As solicitações foram reprovadas`,
                      icon: "CoffeeIcon",
                      variant: "success",
                    },
                  });
                })
                .catch((error) => {
                  this.toast({
                    component: ToastificationContent,
                    props: {
                      title: "Oops!",
                      text: `Ocorreu um erro ao remover os contratos. Entre em contato com o setor de TI.`,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                })
                .finally(() => {
                  this.loading = false;
                  this.checkedAll = false;
                });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
